<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("academic_history") }}
                    </div>
                    <Divider></Divider>
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading">
                <div class="grid">
                  <div class="col-12">
                    <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                      <!-- Selection of Programme -->
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("selection_of_programme") }}
                        </div>

                        <div class="grid">
                          <!-- first_choice -->
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("select_faculty") }} *
                            </div>
                            <div class="">
                              <Dropdown class="w-full" :class="getErrorClass('first_programme_school_id')
                                " optionLabel="label" optionValue="value"
                                ref="ctrlfirst_programme_school_id" v-model="formData.first_programme_school_id"
                                :options="facultiesList" :label="$t('first_programme_school_id')"
                                :placeholder="$t('select')" @change="validateFaculties('1')">
                                <template #option="slotProps">
                                  <div class="flex align-items-center">
                                    <div class="ml-2" v-if="slotProps.option.image">
                                      <Avatar :image="$utils.setImgUrl(
                                        slotProps.option.image
                                      )
                                        " />
                                    </div>
                                    <div>
                                      <div>
                                        {{ slotProps.option.label }}
                                      </div>
                                      <div v-if="slotProps.option.caption" class="text-sm text-500">
                                        {{ slotProps.option.caption }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </Dropdown>
                            </div>
                          </div>
                          <!-- first_programme -->
                          <div class="col-12 md:col-12">
                            <div class="grid">
                              <div class="col-12 md:col-12">
                                <Divider align="left" type="dashed" class="my-2 p-divider-bottom">
                                  <div class="mb-2 text-sm text-primary">
                                    {{ $t("programme") }} *
                                  </div> 
                                </Divider>
                                <div class="">
                                  <Dropdown class="w-full" :filter="true" :class="getErrorClass('first_programme_id')"
                                    optionLabel="label" optionValue="value" ref="ctrlfirst_programme_id"
                                    v-model="formData.first_programme_id" :options="firstProgrammesList"
                                    :label="$t('programme')" :placeholder="$t('select')"
                                    @change="validateProgrammeDuplicate('1')">
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                        <div>
                                          <div>
                                            {{ slotProps.option.label }}
                                          </div>
                                          <div v-if="slotProps.option
                                            .collaboration_with
                                          " class="text-sm text-500">
                                            <span class="text-purple-400">{{
                                              $t("in_collaboration_with")
                                            }}</span>
                                            {{
                                              slotProps.option
                                                .collaboration_with
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </Dropdown>
                                </div>
                                <!-- first_programme.cut_off_point -->
                                <div class="my-2">
                                  <Badge v-if="first_programme &&
                                    first_programme.cut_off_point
                                  "
                                    :value="$t('cut_off_point') + ' : ' + first_programme.cut_off_point" severity="info"
                                    class="mr-2">
                                  </Badge>
                                  <Badge v-if="first_programme &&
                                    first_programme.programme_required_electives
                                  "
                                    :value="$t('required_electives') + ' : ' + first_programme.programme_required_electives"
                                    severity="warning" class="mr-2">
                                  </Badge>
                                </div>
                              </div>
                              <!-- first_programme.programme_duration -->
                              <div v-if="first_programme &&
                                first_programme.programme_durations
                              " class="col-12 md:col-6">
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_duration") }} *
                                </div>
                                <div class="">
                                  <Dropdown class="w-full" ref="ctrlfirst_programme_duration"
                                    :class="getErrorClass('first_programme_duration')"
                                    v-model="formData.first_programme_duration" :options="$utils.toArray(
                                      first_programme.programme_durations
                                    )
                                      " :label="$t('programme_durations')"
                                    :placeholder="$t('select')">
                                  </Dropdown>
                                </div>
                              </div>
                              <!-- first_programme.programme_campuses -->
                              <div v-if="first_programme &&
                                first_programme.programme_campuses
                              " class="col-12 md:col-6">
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_campus") }}
                                </div>
                                <div class="">
                                  <Dropdown class="w-full" ref="ctrlfirst_programme_campus"
                                    v-model="formData.first_programme_campus" :options="$utils.toArray(
                                      first_programme.programme_campuses
                                    )
                                      " :label="$t('campus')"
                                    :placeholder="$t('select')">
                                  </Dropdown>
                                </div>
                              </div>
                              <!-- first_programme.programme_sessions -->
                              <div v-if="first_programme &&
                                first_programme.programme_sessions
                              " class="col-12 md:col-6">
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_session") }}
                                </div>
                                <div class="">
                                  <Dropdown class="w-full" ref="ctrlfirst_programme_session"
                                    v-model="formData.first_programme_session" :options="$utils.toArray(
                                      first_programme.programme_sessions
                                    )
                                      " :label="$t('programme_sessions')"
                                    :placeholder="$t('select')">
                                  </Dropdown>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Tertiary Institutions Attended -->
                      <div class="card nice-shadow-5">
                        <div class="mb-1 text-blue-500 font-bold text-md">
                          {{ $t("institution_attended") }}
                        </div>
                        <div class="col-12 md:col-12">
                          <div class="mt-3 mb-3 font-bold text-sm">
                            {{
                              $t("have_you_attended_any_tertiary_institution")
                            }}
                            *
                          </div>
                          <div class="">
                            <!-- <div class="field mb-0"> -->
                            <Dropdown class="w-full" :class="getErrorClass('has_attended_university')"
                              ref="ctrlhas_attended_university" optionLabel="label" optionValue="value"
                              v-model="formData.has_attended_university" :options="$menus.yes_or_noItems"
                              :label="$t('has_attended_university')" :placeholder="$t('select')">
                            </Dropdown>
                            <!-- </div> -->
                          </div>
                        </div>

                        <div v-if="formData.has_attended_university == yes" class="mt-3 ">
                          <div class="text-xs text-primary text-center">{{
                            $t("you_can_add_up_to_4_records")
                          }}</div>

                          <div class="mt-3">
                            <pg-institutions-attended-page is-sub-page ref="institutions_attendedForm"
                              :show-submit-button="false" v-if="ready"
                              :institutionsattended="formData.institutions_attended"
                              :dob-year="formData.dob_year"></pg-institutions-attended-page>
                          </div>
                        </div>
                      </div>

                      <!-- Statement of Purpose -->
                      <div class="card nice-shadow-5">
                        <div class="mb-1 text-cyan-700 font-bold text-md mb-2">
                          {{ $t("Essay Questions") }}
                        </div>
                        <div class="text-sm">
                          In effort to better understand your experiences and goals, we
                          would like you to address the following questions. Please type your full
                          name on essay.
                        </div>
                        <div class="grid">
                          <div class="col-12 md:col-12">
                            <div class="mt-4 mb-2 font-bold text-sm">
                              {{ $t("research_and_professional_experience") }} *
                            </div>
                            <div class="text-xs text-primary">
                              Attach 2-3 typed pages in which you describe your experiences
                              outside of normal class work which are relevant to your proposed
                              field of study. For example, you may cite professional
                              publications which you have authored and elaborate briefly on
                              the items listed in the previous employment box.
                            </div>
                            <div class="mt-2">
                              <small class="text-xs text-primary">{{
                                $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB")
                              }} .pdf </small>
                              <br>
                              <div class="mt-2">
                                <Uploader :auto="true" :fileLimit="1" :class="getErrorClass('research_proposal')"
                                  :maxFileSize="10000000" accept=".pdf,.docx,.doc" :multiple="true" style="width: 100%"
                                  :label="$t('choose_files_or_drop_files_here')"
                                  upload-path="s3uploader/upload/research_proposal"
                                  v-model="formData.research_proposal">
                                </Uploader>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 md:col-12">
                            <div class="mt-4 mb-2 font-bold text-sm">
                              {{ $t("statement_of_purpose") }} *
                            </div>
                            <div class="text-xs text-primary">
                              A research proposal in which you tell why you wish to pursue
                              graduate study. Describe your ultimate academic career
                              objectives. To the extent you are able, comment on sub-areas
                              within your general field of proposed study which you hope to
                              explore in depth in your project work.
                            </div>
                            <div class="mt-2">
                              <div class="mt-2">
                                <small class="text-xs text-primary">{{
                                  $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB")
                                }} .pdf </small>
                                <br>
                                <div class="mt-2">
                                  <Uploader :auto="true" :fileLimit="1" :class="getErrorClass('statement_of_purpose')"
                                    :maxFileSize="10000000" accept=".pdf,.docx,.doc" :multiple="true"
                                    style="width: 100%" :label="$t('choose_files_or_drop_files_here')"
                                    upload-path="s3uploader/upload/statement_of_purpose"
                                    v-model="formData.statement_of_purpose">
                                  </Uploader>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Recommendation Letters -->
                      <div class="card nice-shadow-5">
                        <div v-if="formData.entry_level == this.phd" class="mb-1 text-indigo-700 font-bold text-md">
                          Two (2) {{ $t("letter_of_recommendation") }}
                        </div>
                        <div v-else class="mb-1 text-blue-500 font-bold text-md">
                          One (1) {{ $t("letter_of_recommendation") }}
                        </div>
                        <div class="grid">
                          <div class="col-12 md:col-12">
                            <div class="text-xs">
                              <div>- Referees are to use only clearly identified official
                                <b>academic or business letterhead</b>
                              </div>
                              <div>- The recommendation letters are to be signed by the
                                referees.</div>
                              <div>- Referees are to describe the applicant's qualifications
                                for graduate study. Should include areas such as:</div>
                              <ul>
                                <li> Performance in independent study or in research groups
                                </li>
                                <li>Intellectual independence</li>
                                <li>Research interests</li>
                                <li>Capacity for analytical thinking</li>
                                <li>Ability to work with others</li>
                                <li>Ability to organize and express ideas clearly</li>
                                <li>Drive and motivation</li>
                              </ul>
                            </div>
                            <div class="mt-3">
                              <pg-recommendation-letters-page is-sub-page ref="recommendation_lettersForm"
                                :show-submit-button="false" v-if="ready"
                                :min-row-count="formData.entry_level == this.phd ? 2 : 1"
                                :recommendation-letters="formData.recommendation_letters">
                              </pg-recommendation-letters-page>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Publications -->
                      <div v-if="formData.entry_level == this.phd" class="card nice-shadow-5">
                        <div class="mb-1 text-pink-300 font-bold text-md">
                          {{ $t("Copies of publications or research materials, if any") }}
                        </div>
                        <div class="grid">
                          <div class="col-12 md:col-12">
                            <div class="text-xs text-primary">{{
                              $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB")
                            }} .pdf. Maximum number of documents you can upload is 5 </div>
                            <br>
                            <div class="mt-2">
                              <Uploader :auto="false" :fileLimit="5" :class="getErrorClass('publications')"
                                :maxFileSize="10000000" accept=".pdf" :multiple="true" style="width: 100%"
                                :label="$t('choose_files_or_drop_files_here')"
                                upload-path="s3uploader/upload/publications" v-model="formData.publications">
                              </Uploader>
                            </div>
                          </div>
                        </div>
                      </div>


                      <!--[form-content-end]-->
                      <div v-if="showSubmitButton" class="text-center my-3">
                        <Button type="submit" :label="$t('save_and_continue')" icon="pi pi-send" :loading="saving" />
                      </div>
                    </form>
                    <slot :submit="submit" :saving="saving"></slot>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { PageMixin } from "../../mixins/page.js";
import { i18n } from "../../services/i18n.js";
import pgInstitutionsAttendedPage from "./pg_academicsdata_institutions.vue";
import pgRecommendationLettersPage from "./pg_academicsdata_recommendations.vue";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "academicsdataApplicantsPage",
  components: { pgInstitutionsAttendedPage, pgRecommendationLettersPage },
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsacademicsdata",
    },
    pagePath: {
      type: String,
      default: "applicants/academicsdata",
    },
    apiPath: {
      type: String,
      default: "applicants/academicsdata",
    },
    pageIndex: { type: Number, default: 3 },
  },
  data() {
    return {
      formData: {
        first_programme_id: "",
        first_programme_school_id: "",
        first_programme_options: "",
        first_programme_session: "",
        first_programme_campus: "Accra",
        first_programme_duration: "",
        has_attended_university: "",
        certificate_url: "",
        recommendation_letters: "",
        research_proposal: "",
        statement_of_purpose: "",
        publications: "",
      },
      first_programme: "",
      yes: "Yes",
      completed: "Completed",
      no: "No",
      phd: "PhD",
      postgradaute: "Postgradaute",
      submitted: false,
    };
  },
  computed: {
    ...mapGetters("applicants", ["academicDataRecord", "facultiesList", "firstProgrammesList", "programmeDataRecord"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      has_attended_university: { required },
      statement_of_purpose: { required },
      recommendation_letters: { required },
      research_proposal: { required },
      publications: {},
      first_programme_id: { required },
      first_programme_school_id: { required },
      first_programme_options: {},
      first_programme_campus: {},
      first_programme_duration: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", ["updateAcademicData", "fetchAcademicData", "getFaculties", "getFacultiesProgrammes"]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchAcademicData(url).then(
        (response) => {
          this.loading = false;
          this.ready = true;
          // this.academicDataRecord = response.data;
          this.formData = response.data;

          this.getFaculties();
          this.loadProgrammeDataFields();

          this.updateFormFields();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      let payload = this.normalizedFormData();
      payload.institutions_attended = this.$refs.institutions_attendedForm?.arrFormData;
      payload.recommendation_letters = this.$refs.recommendation_lettersForm?.arrFormData;
      let errors = false;
      errors = this.$refs.institutions_attendedForm?.validateForm();
      errors = this.$refs.recommendation_lettersForm?.validateForm();

      if (!isFormValid || !errors) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }

      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let data = { id, url, payload };
      this.updateAcademicData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          this.$emit("next", this.pageIndex);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    async loadProgrammes(school_id, selection_category, reload_programmes) {
      let url = `components_data/institution_programmes_option_list?lookup_id=${school_id}`;
      let payload = selection_category;
      let reload = reload_programmes;
      let data = { url, payload, reload };
      await this.getFacultiesProgrammes(data);
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
      this.formData.first_programme_options = this.$utils.toArray(
        this.formData.first_programme_options
      );
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        first_programme_id: "",
        first_programme_school_id: "",
        first_programme_options: "",
        first_programme_session: "",
        first_programme_campus: "",
        first_programme_duration: "",
        research_proposal: "",
        has_attended_university: "",
        statement_of_purpose: "",
        institutions_attended: "",
        recommendation_letters: "",
        publications: "",
      };
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
    validateFaculties(index) {
      // Validate Institution Selections
      if (index == "1" && this.formData.first_programme_school_id) {
        // Fetch Faculty Programmes
        if (this.formData.first_programme_school_id) {
          this.loadProgrammes(
            this.formData.first_programme_school_id,
            index,
            true
          );
        }
      }
    },
    async loadProgrammeDataFields() {
      this.loadProgrammes(this.formData.first_programme_school_id, "1", false)
        .then((res) => {
          this.first_programme = this.firstProgrammesList.find(
            (e) => e.value == this.formData.first_programme_id
          );
        })
        .catch((e) => { });
    },
    validateProgrammeDuplicate(prog_index) {
      if (prog_index == "1") {
        this.first_programme = this.firstProgrammesList.find(
          (e) => e.value == this.formData.first_programme_id
        );
      }
    },
  },
  watch: {},
  async mounted() {
    this.load();
  },
  async created() { },
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>